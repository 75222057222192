<template>
	<div></div>
</template>


<script>

import qs from 'querystring'

export default {
    components: { 
    },
    data: function() {
        return {
           
        }
    },
    methods: {
       onLogin: function() { 
	        
	        const auth = this.$route.query.auth;
	        const origin = this.$route.query.origin;

	        localStorage.setItem("origin",origin);
	        let hasError = false;

	        let authEnc = window.atob(auth.replace("%3D", "=").replace("%3d", "=")); 

	        if(!hasError) {
	          this.axios.post('/cx/login/pin/redirect', qs.stringify({ 
	              loginHost: "xcoin.blx.live",
	              loginId:  authEnc.substr(0, authEnc.indexOf('|')),
	              pin: authEnc.split('|')[1]
	            }))
	                .then((response) => {
	                  localStorage.setItem("tokenId",response.data.tokenId);

	                  window.location.href = "/#/swap";
	                }).catch((err) => {

	                 	console.log(err)
	                })
	        }
	    }
  	},
  	mounted() {
  		this.onLogin();
  	}
}


</script>